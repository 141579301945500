import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ConditionsSearchRequest } from '@mp/contract-management/conditions/domain';

import { ConditionsState } from '../models';

import { ConditionsActions } from './conditions.actions';
import { selectConditionsState } from './conditions.selectors';

@Injectable()
export class ConditionsFacade {
  readonly conditionsState$: Observable<ConditionsState> = this.store$.select(selectConditionsState);

  constructor(private readonly store$: Store) {}

  fetchConditions(searchRequest: ConditionsSearchRequest): void {
    this.store$.dispatch(ConditionsActions.fetchConditions({ searchRequest }));
  }
}
