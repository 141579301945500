import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ConditionsState } from '../models';

import { conditionsFeatureKey } from './conditions.reducer';

export const selectConditionsState = createFeatureSelector<ConditionsState>(conditionsFeatureKey);

export const selectConditionsLoaded = createSelector(selectConditionsState, (state) => state.conditionsLoaded);

export const selectConditions = createSelector(selectConditionsState, (state) => state.conditions);

export const selectConditionsPaginationMetadata = createSelector(
  selectConditionsState,
  (state) => state.conditionsPaginationMetadata,
);

export const selectConditionsFacets = createSelector(selectConditionsState, (state) => state.conditionsFacets);
