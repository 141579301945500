export enum ConditionSortField {
  ConditionNumber = 'ConditionNumber',
  ContractNumber = 'ContractNumber',
  ValidFrom = 'ValidFrom',
  ValidUntil = 'ValidUntil',
  CreatedAt = 'CreatedAt',
  ModifiedAt = 'ModifiedAt',
}

export type ConditionSort = `-${ConditionSortField}` | ConditionSortField;
