import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ConditionsResponse, ConditionsSearchRequest } from '@mp/contract-management/conditions/domain';

export const ConditionsActions = createActionGroup({
  source: 'Contract Management - Conditions',
  events: {
    'Fetch conditions': props<{
      searchRequest: ConditionsSearchRequest;
    }>(),
    'Fetch conditions success': props<{
      conditionsResponse: ConditionsResponse;
    }>(),
    'Fetch conditions error': emptyProps(),
  },
});
