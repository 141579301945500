import { createReducer, on } from '@ngrx/store';

import {
  ConditionQueryField,
  ConditionSortField,
  ConditionStatusFilter,
} from '@mp/contract-management/conditions/domain';
import { LogicalChoiceFilter } from '@mp/shared/data-access';

import { ConditionsState } from '../models';

import { ConditionsActions } from './conditions.actions';

export const conditionsFeatureKey = 'mpctm-core-conditions';

export const initialState: ConditionsState = {
  conditions: [],
  conditionsLoaded: true,
  conditionsSearchRequest: {
    status: ConditionStatusFilter.All,
    contractApproved: LogicalChoiceFilter.All,
    contractExists: LogicalChoiceFilter.All,
    sort: `-${ConditionSortField.ModifiedAt}`,
    field: ConditionQueryField.All,
  },
  conditionsPaginationMetadata: undefined,
  conditionsFacets: undefined,
};

export const conditionsReducer = createReducer(
  initialState,
  on(
    ConditionsActions.fetchConditions,
    (state, { searchRequest }): ConditionsState => ({
      ...state,
      conditionsSearchRequest: searchRequest,
      conditionsLoaded: false,
      conditions: [],
    }),
  ),
  on(
    ConditionsActions.fetchConditionsSuccess,
    (state, { conditionsResponse: { pagination, data, facets } }): ConditionsState => ({
      ...state,
      conditions: data,
      conditionsLoaded: true,
      conditionsPaginationMetadata: pagination,
      conditionsFacets: facets,
    }),
  ),
  on(
    ConditionsActions.fetchConditionsError,
    (state): ConditionsState => ({
      ...state,
      conditionsLoaded: true,
      conditions: [],
      conditionsPaginationMetadata: undefined,
    }),
  ),
);
