export enum ConditionQueryField {
  All = 'All',
  ConditionNumber = 'ConditionNumber',
  ConditionName = 'ConditionName',
  ContractNumber = 'ContractNumber',
  LeadingDepartment = 'LeadingDepartment',
  CategoryBuyer = 'CategoryBuyer',
  SupplierName = 'SupplierName',
  SupplierShortName = 'SupplierShortName',
  SupplierNumber = 'SupplierNumber',
  UserCreated = 'UserCreated',
  UserModified = 'UserModified',
}
